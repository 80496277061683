import React from "react";

interface WelcomeTextProps {
    mobile: Boolean
}

function WelcomeText(props:WelcomeTextProps) {
    if (props.mobile) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
                marginTop: "15px",
                marginBottom: "50px",
                textAlign: "justify",
            }
            }>
                <h3>Hello! I’m Patrick, a software engineer, designer, and muzak enthusiast. I have a Master’s degree from Imperial College London, where I made some robots, apps, services and other stuff you can see on my portfolio.</h3>
                <h3>I used to work at Dyson, but last year I co-founded 2 businesses, one became Imperial College’s digital start-up of the year, and the other won the Mayor's Entrepreneurs Award - so I work on those now. Please feel free to reach out if you have any questions, and thanks for stopping by!</h3>
            </div>
        );
    } else {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
            }
            }>
                <h1 style={{
                    margin: "0px"
                }}>Hello!</h1>
                <h3>I’m Patrick, a software engineer, designer, and muzak enthusiast. I have a Master’s degree from Imperial College London, where I made some products, robots, apps, services, and more.</h3>
                <h3>I used to work at Dyson, but last year I co-founded 2 businesses, one became Imperial College’s digital start-up of the year, and the other won the Mayor's Entrepreneurs Award - so I work on those now.</h3>
                <h3>To see my work, hit portfolio in the top right corner. Thanks for stopping by!</h3>
            </div>
        )}
}

export default WelcomeText;




