import React from "react";
import CornerLink from "../atoms/cornerLink";
import { useNavigate } from "react-router-dom";

interface HomeFooterProps {
}

function HomeFooter(props:HomeFooterProps) {

    const navigate = useNavigate()
  
    return (
        <div className="headerFooterContainer">
                <div className="headerFooterContainerItems left">
                    <CornerLink text={"Twitter >"} onClickFunction={() => window.location.href = "https://twitter.com/pdmcguckian"} class={'hoverLink'}/>
                    <CornerLink text={"LinkedIn >"} onClickFunction={() => window.location.href = "https://www.linkedin.com/in/patrick-d-mcguckian-6b5743191/"} class={'hoverLink'}/>
                </div>
                <div className="headerFooterContainerItems right">
                    <CornerLink text={"© 2023"} onClickFunction={() =>console.log('home')} class={''}/>
                    <CornerLink text={"P. D. McGuckian"} onClickFunction={() =>console.log('home')} class={''}/>
                </div>
        </div>
    );
}

export default HomeFooter;
