import React from "react";

interface DynamicContentProps {
    dynamicContent: string,
    isImage: Boolean
}

function DynamicContent(props:DynamicContentProps) {
    
    const video = require("../../statics/home/" + props.dynamicContent);

    console.log(video)

    if (props.isImage) {
        return (
            <div className="dynamicContentContainer">
                <img className="video-element" src={video} />
            </div>
        );
    } else {
        return(
        <div className="dynamicContentContainer">
            <video autoPlay loop src={video} className="video-element" />
        </div>
        );
    }
}

export default DynamicContent;