import React from "react";

interface projectVideoProps {
    url: string
}

function ProjectVideo(props: projectVideoProps) {
    
    return (
        <div style={{position: "relative",
                        width: "100%",
                        paddingBottom: "56.25%"}}>
            <iframe style={{position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            maxHeight: '453px'}}
                            width="100%" src={props.url} title="Opaque Project Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder={'none'}></iframe>
        </div>  
    );
}

export default ProjectVideo;