import React from "react";
import {Helmet} from 'react-helmet'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./components/organisms/home";
import Nova from "./components/organisms/nova";
import Opaque from "./components/organisms/opaque";
import Artops from "./components/organisms/artops";

function App() {
  const [content, setDynamicContent] = React.useState({page: "home"});

  const Depth = require("./statics/PDMcGuckian_Thesis.pdf");

  return (
    <div className="homePageContainer">
      <Helmet>
          <title>Portfolio | P. D. McGuckian</title>
          <meta name="description" content="Patrick D. McGuckian's personal and professional portfolio." />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home page={content.page} changeFunction={setDynamicContent}/>} />
          <Route path="/portfolio" element={<Home page={content.page} changeFunction={setDynamicContent}/>} />
          <Route path="/artops" element={<Artops />} />
          <Route path="/opaque" element={<Opaque />} />
          <Route path="/nova" element={<Nova />} />
          <Route path="/depth" element={Depth} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
