import React from "react";

interface ContentsLinkProps {
    title: string,
    subtitle: string,
    onHoverFunction: Function,
    mobile: Boolean,
    class: string,
    link: string
  }

function ContentsLink(props: ContentsLinkProps) {
  
    return (
        <div 
            className={props.class}
            style={{
                width: props.mobile ? "100%" : "300px",
                display: "flex",
                flexDirection: "column",
                marginBottom: "12px",
            }}
            onMouseEnter={() => props.onHoverFunction()}>
            <a href={props.link}>
                <h3 style={{textAlign: props.mobile ? "justify" : "center",}}><b>{props.title}</b>{' - ' + props.subtitle + " >"}</h3>
            </a>
        </div>
    );
}

export default ContentsLink;