import React from "react";
import { useNavigate } from "react-router-dom";
import CornerLink from "../atoms/cornerLink";

interface HomeHeaderProps {
    changeFunction: Function,
    dynamicContentFunction: Function,
    usage: 'home' | 'mobhome' | 'project'
}

function HomeHeader(props:HomeHeaderProps) {
    const history = useNavigate();
    const resume = require("../../statics/CV.pdf");
    
    if (props.usage === 'mobhome') {
    return (
        <div className="headerFooterContainerMob">
            <h1 style={{margin: "0px"}}>Patrick D. McGuckian</h1>
            <CornerLink text={"Resume >"} onClickFunction={() => window.open(resume)} class={'hoverLink'}/>
            <CornerLink text={"LinkedIn >"} onClickFunction={() =>  window.location.href = "https://www.linkedin.com/in/patrick-d-mcguckian-6b5743191/"} class={'hoverLink'}/>
            <CornerLink text={"Twitter >"} onClickFunction={() => window.location.href = "https://twitter.com/pdmcguckian"} class={'hoverLink'}/>
        </div>
    );} else if (props.usage === 'home') {
        return (
        <div className="headerFooterContainer">
            <div className="headerFooterContainerItems left">
                <CornerLink text={"Patrick D. McGuckian"} onClickFunction={() =>console.log({page: 'home'})} class={''}/>
                <CornerLink text={"Resume >"} onClickFunction={() => window.open(resume)} class={'hoverLink'}/>
            </div>
            <div className="headerFooterContainerItems right">
                <CornerLink text={"Home >"} onClickFunction={() => {props.changeFunction({page: "home"}); props.dynamicContentFunction({fileName: "vcc.jpg", isImage: true})}} class={'hoverLink'}/>
                <CornerLink text={"Portfolio >"} onClickFunction={() => props.changeFunction({page: "portfolio"})} class={'hoverLink'}/>
            </div>
        </div>
        )
    } else {
        return (
            <div className="headerFooterContainer">
                <div className="headerFooterContainerItems left">
                    <CornerLink text={"Patrick D. McGuckian"} onClickFunction={() => history('/')} class={''}/>
                    <CornerLink text={"Resume >"} onClickFunction={() => window.open(resume)} class={'hoverLink'}/>
                </div>
                <div className="headerFooterContainerItems right">
                    <CornerLink text={"Home >"} onClickFunction={() => history('/')} class={'hoverLink'}/>
                    <CornerLink text={"Portfolio >"} onClickFunction={() => history('/portfolio')} class={'hoverLink'}/>
                </div>
            </div>
        )
    }
}

export default HomeHeader;
