import React from "react";
import dynamicContent from "../molecules/dynamicContent";

interface ContentsLinkProps {
    title: string,
    publication: string,
    onHoverFunction: Function,
    mobile: Boolean
    class: string
    link: string
  }

function MediaLink(props: ContentsLinkProps) {
    
  
    return (
        <div
            className={props.class}
            style={{
                width: props.mobile ? "100%" : "350px",
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
            }}
            onMouseEnter={() => props.onHoverFunction()}>
            <a href={props.link}>
                <h3 style={{textAlign: props.mobile ? "justify" : "center",}}><b>{props.publication}</b><i>{' - ' + props.title + " >"}</i></h3>
            </a>
        </div>
    );
}

export default MediaLink;