import React from "react";
import WelcomeText from "../molecules/WelcomeText";
import DynamicContent from "../molecules/dynamicContent";
import HomeHeader from "../molecules/homeHeader";
import HomeFooter from "../molecules/homeFooter";
import ContentsLinks from "../molecules/contentsLinksArray"


interface homePageProps {
    page: string,
    changeFunction: Function
}

function Home(props: homePageProps) {
    const [content, setDynamicContent] = React.useState({fileName: "vcc.jpg",
                                                              isImage: true});
    if (window.innerWidth > 640) {
        if (props.page === "home") {
            return (
                <div className="homePageContainer">
                    <div className="welcomeTextContentContainer">
                        <HomeHeader changeFunction={props.changeFunction} dynamicContentFunction={() => console.log('')} usage={'home'}/>
                        <div className="welcomeTextContainer">
                            <WelcomeText mobile={false} />
                        </div>
                        <HomeFooter />
                    </div>
                    <DynamicContent dynamicContent={content.fileName} isImage={content.isImage}/>
                </div>
                );
        } else {
            return (
                <div className="homePageContainer">
                    <div className="portfolioContentsContainer">
                        <HomeHeader changeFunction={props.changeFunction} dynamicContentFunction={setDynamicContent} usage={'home'}/>
                        <ContentsLinks onLinkHover={setDynamicContent} changeFunction={props.changeFunction} mobile={false} />
                        <HomeFooter />
                    </div>
                    <DynamicContent dynamicContent={content.fileName} isImage={content.isImage}/>
                </div>
            );
        }
    } else {
        return (
            <div className="homePageContainerMob">
                <HomeHeader changeFunction={props.changeFunction} dynamicContentFunction={setDynamicContent}  usage={'mobhome'}/>
                <img src={require("../../statics/home/vcc.jpg")} style={{width: "100%", marginTop: "50px"}}/>
                <WelcomeText mobile={true}/>
                <ContentsLinks onLinkHover={setDynamicContent} changeFunction={props.changeFunction} mobile={true} />
                <h3 style={{textAlign: "center", marginTop: "150px"}}>© 2023 P. D. McGuckian</h3>
            </div>
        )

    }
    
    }

export default Home;