import React from "react";
import ProjectVideo from "../atoms/projectVideo";
import HomeHeader from "../molecules/homeHeader";
import HomeFooter from "../molecules/homeFooter";
import CornerLink from "../atoms/cornerLink";

function Opaque() {
  
    return (
        <div className="projectPageContainer"> 
            <HomeHeader changeFunction={() => console.log('')} dynamicContentFunction={() => console.log('')} usage={'project'}/>

            <div className="projectPageContentContainer">
                <ProjectVideo url={"https://www.youtube.com/embed/SHrgTPzX9E0"} />
                <div className="projectPageText">
                    <h3>By 2040, organisations will have integrated connected technologies into every aspect of our lives. All our daily activities will require digital services that record our every action to model our behaviour and make major decisions about us. Our homes will be the last place we can hide.</h3>
                    <h3>However, opting-out of data collecting smart home systems to preserve our privacy means living without incredible utility. Opaque solves this problem - allowing us to have 2040's technology without losing our privacy safe space. The Opaque home privacy system achieves this by helping users understand, control, and minimise their home data leakage.</h3>
                </div>

                <div style={{overflow: "hidden", width:"100%", overflowX: 'scroll'}}>
                    <div className="p_graphic" style={{overflowX: 'scroll'}}>
                        <div className="p_graphicthird" style={{padding: "10px"}}>
                            <img src={require("../../statics/opaque/understand.jpg")} className="p_opaqueimg"/>
                            <h1>Understand the extent and main sources of home data leakage.</h1>
                        </div>
                        <div className="p_graphicthird" style={{padding: "10px"}}>
                            <img src={require("../../statics/opaque/control.jpg")} className="p_opaqueimg"/>
                            <h1>Control what kind of data can and cannot leave the home.</h1>
                        </div>
                        <div className="p_graphicthird" style={{padding: "10px"}}>
                            <img src={require("../../statics/opaque/minimise.jpg")} className="p_opaqueimg"/>
                            <h1>Minimise the number of tasks that require data leakage.</h1>
                        </div>
                    </div>
                </div>

                <div className="projectPageText">
                    <h3>At the start of the project, 3 key product requirements were set out:</h3>

                    <h3>1. It must be able to control the data leakage of any device in the home, regardless of whether it's manufacture officially supports our system.</h3>

                    <h3>2. It's features must be intuitive to non-data savy consumers, so that if it ever restricts a devices functionality to increase privacy, it will be clear to the user.</h3>

                    <h3>3. It's features must be obviously useful, so that everyday consumers will see in what circumstances they would use and benefit from them.</h3>

                    <h3>To meet these, our system, embodied as a wall mounted control panel, connects to WiFi and creates a new network for all home and personal devices to connect to - meaning it can control all data leaving the household.</h3>
                </div>

                <img src={require("../../statics/opaque/format.jpg")} style={{width: "100%"}}/>

                <div className="projectPageText">
                    <h3>Our product makes the complex and abstract topic of data privacy accessible by breaking down data into heuristic categories and using them to visualise data leakage as bubbles representing each category, and a metric representing total leakage over the last 30 minutes. These categories are also combined into modes that, when selected, stop related categories of data leaking the home - so users can effortlessly adjust their entire homes privacy based on their current need.</h3>
                    <h3>None of this requires support from the product's manufacturer to work - meaning any device connected to the home's network can have it's data leakage monitored and controlled.    However, a manufacturer can choose to support the system by adopting Opaque's built in locally processed voice assistant and open smart home API's to perform basic smarthome functions without leaking data.</h3>
                </div>

                <div style={{overflow: "hidden", width:"100%", overflowX: 'scroll'}}>
                    <div className="p_graphic" style={{overflowX: 'scroll'}}>
                        <div className="p_graphicthird" style={{paddingBottom: "20px", padding: "10px"}}>
                            <video autoPlay muted className="p_graphicvid">
                                <source src={require("../../statics/opaque/mode_switching.mp4")} type="video/mp4"/>
                            </video>
                            <h1>Turn the dial to control what data can leave the home.</h1>
                        </div>
                        <div className="p_graphictwothird" style={{paddingBottom: "20px", paddingTop: "20px", height: "282px"}}>
                            <img src={require("../../statics/opaque/deep_packet_analysis.jpg")} className="p_opaqueimg"/>
                            <h1>A speculative futute technology known as "deep packet inspection" would classify each data packet.</h1>
                        </div>
                    </div>
                </div>

                <div className="projectPageText">
                    <h3>We combined all these separate technologies into a single system capable of handling any device or user VUI request - creating a flow chart to map this process.</h3>
                    <h3>We user tested our concept throughout the development process to assure it met our product requirements and gave a good user expereince. To do this we built a web demo simulating a home in 2040 fitted with Opaque, full of smarthome and personal devices attempting to leak data. This now serves as a demo of our system which can be explored using the link below.</h3>
                </div>

                <img src={require("../../statics/opaque/data_flow.jpg")} style={{width: "100%"}}/>
            </div>

            <HomeFooter />
        </div>
    );
}

export default Opaque;