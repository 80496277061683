import React from "react";
import ProjectVideo from "../atoms/projectVideo";
import HomeHeader from "../molecules/homeHeader";
import HomeFooter from "../molecules/homeFooter";
import CornerLink from "../atoms/cornerLink";

function Nova() {
  
    return (
        <div className="projectPageContainer"> 
            <HomeHeader changeFunction={() => console.log('')} dynamicContentFunction={() => console.log('')} usage={'project'}/>

            <div className="projectPageContentContainer">
                <ProjectVideo url={"https://www.youtube.com/embed/fDbf7welIbs"}/>
                <div className="projectPageText">
                    <h3>Only 25% of software engineers are female, and with girls only accounting for 21% of computing GCSE students this seems unlikely to change in the near future. The Royal Society believes engaging pre-GCSE girls with programming could help address this issue, so we set this as our second year project aim.</h3>
                    <h3>There are many products in this space, but across interviews with families, focus groups with Tutors, and teardowns of competitors, we discovered that the existing tools were uninteractive, male-orientated, and either too simple or not accessible for beginners. Nova aims to address this, using physical input and output to make programming more interactive, step by step tutorials to make it more accessible, and personal projects to allow children to express their creativity.</h3>
                </div>

                
                <div style={{overflow: "hidden", width:"100%", overflowX: 'scroll'}}>
                    <div className="p_graphic">
                        <div className="p_graphicthird" style={{backgroundColor: "white"}}>
                            <img src={require("../../statics/nova/ideation.jpg")} className="p_fullimggraphic"/>
                        </div>
                        <div className="p_graphictwothird" style={{backgroundColor: "white"}}>
                            <img src={require("../../statics/nova/novaHero.jpg")} className="p_fullimggraphic"/>
                        </div>
                    </div>
                </div>

                <div className="projectPageText">
                    <h3>Nova is a hand held, battery powered robot with numerous built in codable functions. Children can write Python programs for the robot on a webapp - either following tutorials or creating their own projects. The robot connects to WiFi to download these programs which users can run through the robots UI.</h3>
                </div>
                
                <div className="p_fullvid">
                    <video autoPlay muted className="p_fullvid">
                        <source src={require("../../statics/nova/runthrough.mp4")} type="video/mp4"/>
                    </video>
                </div>


                <div className="projectPageText">
                    <h3>We initially designed the robots battery powered electronics package around a Raspberry Pi 3A+ and a charging circuit harvested from a battery bank - however after discussions with electronics experts, we went on to scope a system with a low cost ESP32 chip. Our prototypes could support driven wheels, a touch display, camera, microphone, speaker, ultrasonic depth sensors, and RGB LEDs.</h3>
                    <h3>Breadboard prototypes were used to test our technology, and a custom PCB was designed but workshop closures as a result of the COVID-19 pandemic meant we used prototype PCB in the final machine.</h3>
                </div>


                <div style={{overflow: "hidden", width:"100%", overflowX: 'scroll'}}>
                    <div className="p_graphic" style={{overflowX: 'scroll'}}>
                        <div className="p_graphichalf">
                            <img src={require("../../statics/nova/breadboard.jpg")} className="p_fullimggraphic"/>
                        </div>
                        <div className="p_graphichalf">
                            <img src={require("../../statics/nova/pcb.jpg")} className="p_fullimggraphic"/>
                        </div>
                    </div>
                </div>

                <div className="projectPageText">
                    <h3>A website was chosen over an app so the robot could be coded on any device. We built it from scratch in under two weeks using Django. User's code was stored in a MySQL database as it allows remote access, so the bot could connect to it to download users code programs. User's can set up accounts, create their own personal projects or follow along with step-by-step tutorials.</h3>
                    <h3>The device's OS is built using PyQT and Python, and runs user code by pulling the files from a MySQL database and saving them .py files. Python's subprocess module is used to execute the user's code, allowing a button to be connected to an interrupt pin to kill the process if the users code was stuck in an endless loop.</h3>
                </div>

                <div style={{overflow: "hidden", width:"100%", overflowX: 'scroll'}}>
                    <div className="p_graphic" style={{overflowX: 'scroll'}}>
                        <div className="p_graphichalf">
                            <img src={require("../../statics/nova/webapp1.jpg")} className="p_fullimggraphic"/>
                        </div>
                        <div className="p_graphichalf">
                            <img src={require("../../statics/nova/webapp2.jpg")} className="p_fullimggraphic"/>
                        </div>
                    </div>
                </div>

                <div className="projectPageText">
                    <h3>As the product was designed for young children it needed to be cheap, compact, and durable. To assure the product could survive being dropped the electronics were housed in an internal frame, and the FEA Testing was used to design the enclousure to flex when dropped to dampen the impact force.</h3>
                    <h3>To reduce unit cost DFMA Theory and Plastic Flow Simulations were used to minimise the number of parts, the cost of manufacturing them and time and complexity of the products assembly.</h3>
                </div>

                <div className="p_fullvid">
                    <video autoPlay muted className="p_fullvid">
                        <source src={require("../../statics/nova/explosion.mov")} type="video/mp4"/>
                    </video>
                </div>
            </div>

            <HomeFooter />
        </div>
    );
}

export default Nova;