import React from "react";
import ProjectVideo from "../atoms/projectVideo";
import HomeHeader from "../molecules/homeHeader";
import HomeFooter from "../molecules/homeFooter";
import CornerLink from "../atoms/cornerLink";

function Opaque() {
  
    return (
        <div className="projectPageContainer"> 
            <HomeHeader changeFunction={() => console.log('')} dynamicContentFunction={() => console.log('')} usage={'project'}/>

            <div className="projectPageContentContainer">
                <ProjectVideo url={"https://www.youtube.com/embed/PjoidWLsp_A"} />
                <div className="projectPageText">
                    <h3>The brief was to build a machine with a repeatable user interaction that had no useful application - it was just for fun!</h3>
                    <h3>We wanted to build a machine that could play a full game of Blackjack - shuffling & dealing cards, reading their values, deciding to hit or stick, and communicating with it's user.</h3>
                </div>

                <img src={require("../../statics/artops/systemDiagram.png")} style={{width: "100%"}}/>

                <div className="projectPageText">
                    <h3>We needed to design a set of mechanisms to control playing cards. Inspired by cash machines, we chose to shuffle and deal the cards using wheels, and move the deck between subsystems using a belt driven slider. We built proof of principle prototypes to assure this was feasible before iteratively developing the mechanisms until they functioned as designed.</h3>
                </div>

                <div className="p_fullvid">
                    <video autoPlay muted className="p_fullvid">
                        <source src={require("../../statics/artops/artops_mechanisms.mp4")} type="video/mp4"/>
                    </video>
                </div>

                <div className="projectPageText">
                    <h3>To play the game the system needed to track the values of both the players and its own hand. Cameras were placed above the card decks and a custom computer vision system was developed to read the their values.</h3>
                    <h3>To improve efficiency it worked by isolating the rank value on the top left of the card, and then passing this now much smaller image through a neural network to predict the rank.</h3>
                    <h3>To train the model we built our own dataset by using the machine as an imaging rig to photo different playing cards, and data augmentation techniques to avoid over-fitting.</h3>
                </div>

                <img src={require("../../statics/artops/cv.jpg")} style={{width: "100%"}}/>

                <div className="projectPageText">
                    <h3>We had a strict size limit of 250x250x300mm, so we made all of the mechanism components structural to reduce the number of components in the assembly. Before manufacture, we created a full CAD assembly of the machine to assure we met the tight tolerances required to manipulate playing cards.</h3>
                    <h3>The parts were either laser cut or 3D printed, and the machine was assembled without glue. A Raspberry Pi powered the UI, computer vision system, and dealer mechanism, while a serially communicated Arduino controlled the shuffler.</h3>
                </div>

            </div>

            <HomeFooter />
        </div>
    );
}

export default Opaque;