import React from "react";
import HomeHeader from "../molecules/homeHeader";
import HomeFooter from "../molecules/homeFooter";
import ContentsLink from "../atoms/contentsLink";
import MediaLink from "../atoms/mediaLink";

interface portfolioContentsProps {
    onLinkHover: Function,
    changeFunction: Function,
    mobile: Boolean
}
function ContentsLinks(props:portfolioContentsProps) {

    const depth = require("../../statics/PDMcGuckian_Thesis.pdf")

    if (props.mobile) {
        var classType = "portfolioContentsListContainerMob"
    } else {
        var classType = "portfolioContentsListContainer"
    }

    return (
        <div className={classType}>
            <div className="linkSectionContainer">
                <h1 style={{marginBottom: "5px"}}>Startups</h1>
                <ContentsLink title={'Team Repair'} subtitle={'an entirely circular subscription teaching children STEM and repair'} onHoverFunction={() => props.onLinkHover({fileName: "teamrepair_main.mp4", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'https://team.repair'} />
                <ContentsLink title={'Attic'} subtitle={'setup, manage, and revoke any of your team’s work apps in seconds'} onHoverFunction={() => props.onLinkHover({fileName: "attic.png", isImage: true})} mobile={props.mobile} class={'hoverLink'} link={'https://useattic.com'} />
            </div>
            <div className="linkSectionContainer">
                <h1 style={{marginBottom: "5px"}}>University Projects</h1>
                <ContentsLink title={'Nova'} subtitle={'a robot that teaches Python'} onHoverFunction={() => props.onLinkHover({fileName: "nova_main.mp4", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'/nova'}/>
                <ContentsLink title={'Opaque'} subtitle={'2040’s home privacy system'} onHoverFunction={() => props.onLinkHover({fileName: "opaque_main.mp4", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'/opaque'}/>
                <ContentsLink title={'Artops'} subtitle={'a blackjack playing robot'} onHoverFunction={() => props.onLinkHover({fileName: "artops_main.mp4", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'/artops'}/>
                <ContentsLink title={'Depth'} subtitle={'a holistic privacy service'} onHoverFunction={() => props.onLinkHover({fileName: "depth_main.mov", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={depth}/>
            </div>
            <div className="linkSectionContainer">
                <h1 style={{marginBottom: "5px"}}>Notable Media</h1>
                <MediaLink publication={'World Economic Forum'} title={'This Start-Up Sends Kids Broken Toys to Repair'} onHoverFunction={() => props.onLinkHover({fileName: "wef_main.mp4", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'https://www.weforum.org/videos/this-start-up-sends-kids-broken-toys-to-repair'}/>
                <MediaLink publication={'The Times'} title={'Broken gadgets offer neat fix for skills shortage'} onHoverFunction={() => props.onLinkHover({fileName: "times_main.mov", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'https://www.thetimes.co.uk/article/sending-children-broken-toys-can-fix-throwaway-culture-x0d8j5fdk'}/>
                <MediaLink publication={'London Evening Standard'} title={'Introducing the Founders of Team Repair, Startup Angel Competition Winners'} onHoverFunction={() => props.onLinkHover({fileName: "axa_main.mp4", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'https://www.standard.co.uk/business/axastartupangel/sustainable-business-winner-axa-startup-angel-competition-b1024143.html'}/>
                <MediaLink publication={'Imperial College London'} title={'Imperial’s most promising startups get set to pitch their business ideas'} onHoverFunction={() => props.onLinkHover({fileName: "vcc_main.mp4", isImage: false})} mobile={props.mobile} class={'hoverLink'} link={'https://www.imperial.ac.uk/news/234880/imperials-most-promising-student-startups-pitch/'}/>
            </div>
        </div>
    );
}

export default ContentsLinks;