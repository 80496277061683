import React from "react";

interface CornerLinkProps {
    text: string,
    onClickFunction: Function
    class: string
  }


function CornerLink(props: CornerLinkProps) {
  
    return (
        <div onClick={() => props.onClickFunction()} className={props.class}>
            <h2>{props.text}</h2>
        </div>
    );
}

export default CornerLink;
